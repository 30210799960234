import React from 'react';
import PropTypes from 'prop-types';

import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';

import { ChartCanvas, Chart } from 'react-stockcharts';
import {
  BarSeries,
  CandlestickSeries,
  BollingerSeries,
  DataSeries,
  RSISeries,
} from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import {
  CrossHairCursor,
  // EdgeIndicator,
  // CurrentCoordinate,
  MouseCoordinateX,
  MouseCoordinateY,
} from 'react-stockcharts/lib/coordinates';

import { LabelAnnotation, Annotate } from 'react-stockcharts/lib/annotation';
import { ema, sma, rsi, bollingerBand } from 'react-stockcharts/lib/indicator';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import {
  OHLCTooltip,
  // MovingAverageTooltip,
  // MACDTooltip,
} from 'react-stockcharts/lib/tooltip';
import { fitWidth } from 'react-stockcharts/lib/helper';
import { last, toObject } from 'react-stockcharts/lib/utils';

const bbStroke = {
  top: '#964B00',
  middle: '#000000',
  bottom: '#964B00',
};

const bbFill = '#4682B4';

class CandleStickStockScaleChartWithVolumeBarV3 extends React.Component {
  // constructor() {
  //   super();
  // }

  render() {
    const { type, data: initialData, width, ratio } = this.props;

    const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(
      (d) => d.date,
    );
    const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(
      initialData,
    );

    const maxSignalAnnotationProps = {
      fontFamily: 'Glyphicons Halflings',
      fontSize: 15,
      fill: (d) => '#060F8F',
      opacity: 0.8,
      text: (d) => d.maxSignal,
      y: ({ yScale, datum, plotData }) => {
        console.info(datum);
        if (datum.maxSignal === '2') {
          return yScale(datum.low) + 17; // low
        }
        return yScale(datum.high) - 4; // high
      },
      // onClick: console.log.bind(console),
      // onMouseOver: console.log.bind(console),
    };

    const minSignalAnnotationProps = {
      fontFamily: 'Glyphicons Halflings',
      fontSize: 15,
      // eslint-disable-next-line no-unused-vars
      fill: (d) => '#FF0000',
      opacity: 0.8,
      // eslint-disable-next-line no-unused-vars
      text: (d) => d.minSignal,
      y: ({ yScale, datum, plotData }) => {
        if (datum.minSignal === '2') {
          return yScale(datum.high) - 4; // high
        }
        return yScale(datum.low) + 17; // low
      },
      // onClick: console.log.bind(console),
      // onMouseOver: console.log.bind(console),
    };

    const start = xAccessor(last(data));
    const end = xAccessor(data[Math.max(0, data.length - 100)]);
    const xExtents = [start, end];

    const height = 600;
    const margin = { left: 70, right: 70, top: 20, bottom: 30 };
    const gridHeight = height - margin.top - margin.bottom;
    const gridWidth = width - margin.left - margin.right;

    const showGrid = true;
    const yGrid = showGrid
      ? {
          innerTickSize: -1 * gridWidth,
          tickStrokeDasharray: 'Solid',
          tickStrokeOpacity: 0.08,
          tickStrokeWidth: 1,
        }
      : {};
    const xGrid = showGrid
      ? {
          innerTickSize: -1 * gridHeight,
          tickStrokeDasharray: 'Solid',
          tickStrokeOpacity: 0.08,
          tickStrokeWidth: 1,
        }
      : {};

    // https://gist.github.com/rrag/b993d5fcc5c09dd66a6e
    const bb = bollingerBand()
      .merge((d, c) => {
        // eslint-disable-next-line no-param-reassign
        d.bb = c;
      })
      .accessor((d) => d.bb);

    return (
      <ChartCanvas
        height={height}
        ratio={ratio}
        width={width}
        margin={margin}
        type={type}
        seriesName="MSFT"
        data={data}
        xScale={xScale}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xExtents={xExtents}
        //     mouseMoveEvent={false}
        // panEvent={false}
        // zoomEvent={false}
      >
        {/* Candlestick Chart */}
        <Chart id={1} height={400} yExtents={(d) => [d.high, d.low]}>
          <YAxis axisAt="right" orient="right" ticks={5} {...yGrid} />
          <XAxis
            axisAt="bottom"
            orient="bottom"
            showTicks={false}
            outerTickSize={0}
          />
          <MouseCoordinateY
            at="right"
            orient="right"
            displayFormat={format('.2f')}
          />
          <MouseCoordinateX
            at="bottom"
            orient="bottom"
            displayFormat={timeFormat('%Y-%m-%d')}
          />
          <CandlestickSeries />
          <BollingerSeries
            yAccessor={(d) => d.bb}
            stroke={bbStroke}
            fill={bbFill}
          />
          <OHLCTooltip origin={[-40, 0]} />

          <Annotate
            with={LabelAnnotation}
            when={(d) => d.maxSignal}
            usingProps={maxSignalAnnotationProps}
          />

          <Annotate
            with={LabelAnnotation}
            when={(d) => d.minSignal}
            usingProps={minSignalAnnotationProps}
          />
        </Chart>
        {/* Volume Chart */}
        <Chart
          id={2}
          origin={(w, h) => [0, h - 150]}
          height={150}
          yExtents={(d) => d.volume}
        >
          <XAxis axisAt="bottom" orient="bottom" {...xGrid} />
          <YAxis
            axisAt="left"
            orient="left"
            ticks={5}
            tickFormat={format('.2s')}
            {...yGrid}
          />
          <BarSeries
            yAccessor={(d) => d.volume}
            fill={(d) => (d.close > d.open ? '#6BA583' : 'red')}
          />
        </Chart>
        {/* RSI Chart
        <Chart
          id={3}
          yMousePointerDisplayLocation="right"
          yMousePointerDisplayFormat={(y) => y.toFixed(2)}
          height={125}
          origin={(_w, h) => [0, h - 250]}
          padding={{ top: 10, right: 0, bottom: 10, left: 0 }}
        >
          <XAxis
            axisAt="bottom"
            orient="bottom"
            showTicks={false}
            outerTickSize={0}
          />
          <YAxis axisAt="right" orient="right" ticks={4} />
          <DataSeries id={0} indicator={rsi} options={{ period: 14 }}>
            <RSISeries />
          </DataSeries>
        </Chart> */}
        <CrossHairCursor />
      </ChartCanvas>
    );
  }
}
CandleStickStockScaleChartWithVolumeBarV3.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['svg', 'hybrid']),
};

CandleStickStockScaleChartWithVolumeBarV3.defaultProps = {
  type: 'hybrid',
};

export default fitWidth(CandleStickStockScaleChartWithVolumeBarV3);
