import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import { timeParse } from 'd3-time-format';

import Chart from './Chart';
import { addABCAnnotation } from './abc';

const parseDate = timeParse('%Y-%m-%d');

const STOCK_DATA_QUERY = gql`
  query getStockSpot($stockcode: String!) {
    stock_spot(code: $stockcode) {
      openValue
      closeValue
      highValue
      lowValue
      volume
      dateTime
    }
  }
`;

function InteractiveStockChart({ stockcode }) {
  console.info(`Rendering Stock: ${stockcode}`);
  const { loading, error, data } = useQuery(STOCK_DATA_QUERY, {
    variables: { stockcode },
  });
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error...</div>;
  }
  // eslint-disable-next-line camelcase
  const { stock_spot } = data;
  const convertedSpot = stock_spot.map((d) => ({
    date: parseDate(d.dateTime),
    open: d.openValue,
    close: d.closeValue,
    high: d.highValue,
    low: d.lowValue,
    volume: d.volume,
  }));

  const convertedData = addABCAnnotation(convertedSpot, 4);
  return <Chart type="hybrid" data={convertedData} />;
}

InteractiveStockChart.propTypes = {
  stockcode: PropTypes.string.isRequired,
};

export default InteractiveStockChart;
