function findAdjMax(data, startIdx) {
  //   assert(startIdx > 0);
  const dataLength = data.length;
  for (let idx = startIdx; idx < dataLength - 1; idx += 1) {
    const { low, high } = data[idx];
    if (data[idx - 1].high < high && data[idx + 1].high < high) {
      return idx;
    }
    if (data[idx - 1].low > low && data[idx + 1].low > low) {
      return -1; // another local min exist failed
    }
  }
  return -1; // not found
}

function findAdjMin(data, startIdx) {
  //   assert(startIdx > 0);
  const dataLength = data.length;
  for (let idx = startIdx; idx < dataLength - 1; idx += 1) {
    const { low, high } = data[idx];
    if (data[idx - 1].low > low && data[idx + 1].low > low) {
      return idx;
    }
    if (data[idx - 1].high < high && data[idx + 1].high < high) {
      return -1; // another local max exist failed
    }
  }
  return -1; // not found
}

function findCorrectionMin(data, adjMax, fullCorrectionCount) {
  const startIdx = adjMax + 1;
  const dataLength = data.length;
  const { low, high } = data[adjMax];
  let localCount = fullCorrectionCount;

  for (
    let idx = startIdx;
    idx < dataLength - 1 && localCount > 0;
    idx += 1, localCount -= 1
  ) {
    if (
      data[idx].low < low &&
      data[idx].high < high &&
      data[idx + 1].high > data[idx].high
    ) {
      return idx;
    }
  }
  return -1; // not found
}

function findCorrectionMax(data, adjMin, fullCorrectionCount) {
  const startIdx = adjMin + 1;
  const dataLength = data.length;
  const { low, high } = data[adjMin];
  let localCount = fullCorrectionCount;

  for (
    let idx = startIdx;
    idx < dataLength - 1 && localCount > 0;
    idx += 1, localCount -= 1
  ) {
    if (
      data[idx].low > low &&
      data[idx].high > high &&
      data[idx + 1].low < data[idx].low
    ) {
      return idx;
    }
  }
  return -1; // not found
}

function addABCAnnotation(data, fullCorrectionCount) {
  const d = data;
  const dataLength = data.length;
  for (let idx = 1; idx < dataLength - 1; idx += 1) {
    // start at 1 and // end at n-1
    const { low } = data[idx];
    if (data[idx - 1].low > low && data[idx + 1].low > low) {
      // it's local min, start the checking
      const adjMax = findAdjMax(data, idx + 1);
      if (adjMax >= 0) {
        const correctMin = findCorrectionMin(data, adjMax, fullCorrectionCount);
        if (correctMin >= 0) {
          // find the result
          if (d[correctMin].low > d[idx].low) {
            d[idx].minSignal = '1';
            d[adjMax].minSignal = '2';
            d[correctMin].minSignal = '3';
            idx = correctMin;
          } else {
            // if 3 is lower than 1, should use 3 as new 1 and recalc
            idx = correctMin - 1;
          }
        }
      }
    }
    const { high } = data[idx];
    if (data[idx - 1].high < high && data[idx + 1].high < high) {
      // console.log("find local max at " + idx)
      // it's local max, start the checking
      const adjMin = findAdjMin(data, idx + 1);
      if (adjMin >= 0) {
        // console.log("find adj min at " + adjMin)
        const correctMax = findCorrectionMax(data, adjMin, fullCorrectionCount);
        if (correctMax >= 0) {
          // console.log("find correction at " + correctMax)
          // find the result
          if (d[correctMax].high < d[idx].high) {
            d[idx].maxSignal = '1';
            d[adjMin].maxSignal = '2';
            d[correctMax].maxSignal = '3';
            idx = correctMax;
          } else {
            idx = correctMax - 1;
          }
        }
      }
    }
  }
  return d;
}

export { addABCAnnotation };
export default addABCAnnotation;
