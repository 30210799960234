// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px}._13R3L{padding-left:20px;padding-right:20px}._3n5Rg{margin:0 auto;padding:0 0 40px;max-width:1000px;max-width:var(--max-content-width)}._3U6kL{margin:0 0 2rem}.tz7Kf{font-size:24px;font-size:1.5rem}._17vKC h1,._17vKC h2,._17vKC h3,._17vKC h4,._17vKC h5,._17vKC h6{font-size:18px;font-size:1.125rem}._17vKC pre{white-space:pre-wrap;font-size:14px;font-size:.875rem}._17vKC img{max-width:100%}", ""]);
// Exports
exports.locals = {
	"root": "_13R3L",
	"container": "_3n5Rg",
	"newsItem": "_3U6kL",
	"newsTitle": "tz7Kf",
	"newsDesc": "_17vKC"
};
module.exports = exports;
